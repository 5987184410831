const isBrowser = typeof window !== 'undefined'; // Check if window is defined (browser environment)

const INIT_STATE = {
    program_detail: isBrowser && localStorage.getItem("program_detail") 
        ? JSON.parse(localStorage.getItem("program_detail")) 
        : {},
    program_list: isBrowser && localStorage.getItem("program_list") 
        ? JSON.parse(localStorage.getItem("program_list")) 
        : [],
    all_program: isBrowser && localStorage.getItem("all_program") 
        ? JSON.parse(localStorage.getItem("all_program")) 
        : []
};

export default (state = INIT_STATE, action) => {
    switch (action.type) {
        case "ADD_PROGRAM_DETAIL":
            if (isBrowser) {
                try {
                    localStorage.setItem("program_detail", JSON.stringify(action.payload));
                } catch (error) {
                    console.error("Failed to serialize `program_detail`: ", error);
                }
            }
            return {
                ...state,
                program_detail: action.payload
            };

        case "ADD_PROGRAM_LIST":
            if (isBrowser) {
                try {
                    localStorage.setItem("program_list", JSON.stringify(action.payload));
                } catch (error) {
                    console.error("Failed to serialize `program_list`: ", error);
                }
            }
            return {
                ...state,
                program_list: action.payload
            };

        case "ADD_ALL_PROGRAM_LIST":
            if (isBrowser) {
                try {
                    localStorage.setItem("all_program", JSON.stringify(action.payload));
                } catch (error) {
                    console.error("Failed to serialize `all_program`: ", error);
                }
            }
            return {
                ...state,
                all_program: action.payload
            };

        default:
            return state;
    }
};
