let ReactPixel = null;

export const initFacebookPixel = (pixelId) => {
  if (typeof window !== 'undefined') {
    // Dynamically import the library only on the client
    import('react-facebook-pixel').then((module) => {
      ReactPixel = module.default;
      ReactPixel.init(pixelId);
      ReactPixel.pageView();
    });
  }
};

export const trackEvent = (event, data = {}) => {
  if (typeof window !== 'undefined' && ReactPixel) {
    ReactPixel.track(event, data);
  }
};
